<template>
  <div class="container">
    <!-- 面包屑 -->
    <div class="header_top">
      <div class="headertop_left">
        <span class="task_title">任务管理</span>
        <span class="task_icon">-</span>
        <span class="nav">查看明细</span>
      </div>
      <div class="headertop_right" @click="goback">
        <img src="../../../../../assets/images/back.png" alt="" />
        <span class="goback">返回上一层</span>
      </div>
    </div>
    <!-- 头部搜索区域 -->
    <div class="head">
      <div class="header_left">
        <a-form-model class="head_form" :mode="taskForm">
          <a-form-model-item label="姓名">
            <a-input placeholder="请输入姓名" v-model="taskForm.name" :maxLength="20" />
          </a-form-model-item>
          <a-form-model-item label="状态" prop="state">
            <a-select v-model="taskForm.state" placeholder="全部">
              <a-select-option v-for="item in sourseList" :key="item.code" :value="item.code">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div class="header_btn">
          <a-button class="search" @click="search">查询</a-button>
          <a-button class="reset" @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <!-- 表格数据 -->
    <div class="content">
      <a-table style="width: 100%" :columns="columns" :data-source="data" :pagination="false" :row-selection="rowSelection">
        <!-- 序号 -->
        <span slot="(index,text,record)" slot-scope="index, record, text">{{ text }}</span>
        <!-- 姓名 -->
        <span slot="userName" slot-scope="text, records">
          <img class="avatar" :src="records.echoMap.avatar" alt="" />
          <span>{{ records.echoMap.realname }}</span>
        </span>
        <span slot="staff" slot-scope="text, records">
          <span>{{ records.echoMap.staff }}</span>
        </span>
        <!-- 所属党支部 -->
        <span slot="deptName" slot-scope="text, records">{{ records.echoMap.partybranch }}</span>
        <!-- 手机号 -->
        <span slot="tel" slot-scope="text, records">{{ records.echoMap.tel }}</span>
        <!-- 获得积分 -->
        <span slot="integration" slot-scope="text, records">
          <span v-if="records.echoMap.integration.substr(0, 1) == '+'"> {{ Number(records.echoMap.integration.substring(1)).toFixed(1) }}</span>
          <span v-if="records.echoMap.integration.substr(0, 1) != '+'"> {{ Number(records.echoMap.integration).toFixed(1) }}</span>
        </span>
        <!-- 状态 -->
        <span slot="state" slot-scope="text, records">
          <span v-if="records.echoMap.state === '未完成'" style="color:#999999">未完成</span>
          <span v-if="records.echoMap.state === '待完成'" style="color:#999999">待完成</span>
          <span v-if="records.echoMap.state === '已完成'" style="color:#353535">已完成</span>
        </span>
        <!-- 查看 -->
        <span class="action" slot="action" slot-scope="text, records">
          <router-link
            style="color:#f44600"
            :to="{
              path: `/member/task/watch/watchinfo?id=${records.id}`
            }"
            >查看</router-link
          >
        </span>
      </a-table>
      <Pagination :current="current" :page-size="size" :total="total" @change="change" />
    </div>
  </div>
</template>

<script>
import { listUserTask } from '@/api/task'
import Pagination from '@/components/pagination'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      taskForm: {
        name: '',
        state: undefined
      },
      sourseList: [
        {
          name: '待完成',
          code: 0
        },
        {
          name: '已完成',
          code: 1
        },
        {
          name: '未完成',
          code: 2
        }
      ],
      selectedRowKeys: [],
      data: [],
      total: 0,
      size: 10,
      current: 1,
      id: '',
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '姓名',
          dataIndex: 'realname',
          scopedSlots: { customRender: 'userName' }
        },
        {
          title: '教职工号',
          dataIndex: 'staff',
          scopedSlots: { customRender: 'staff' }
        },
        {
          title: '所属党支部',
          dataIndex: 'deptName',
          scopedSlots: { customRender: 'deptName' }
        },
        {
          title: '手机号',
          dataIndex: 'tel',
          scopedSlots: { customRender: 'tel' }
        },
        {
          title: '获得积分',
          dataIndex: 'integration',
          scopedSlots: { customRender: 'integration' }
        },
        {
          title: '状态',
          dataIndex: 'state',
          scopedSlots: { customRender: 'state' }
        },
        {
          title: '报名时间',
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.listUserTask()
    }
  },
  methods: {
    goback() {
      this.$router.push('/member/task/list')
    },
    // 获取列表
    async listUserTask() {
      const data = await listUserTask({ taskId: this.id, realName: this.taskForm.name, status: this.taskForm.state })
      if (data.code === 200) {
        this.data = data.data.records
        this.total = data.data.total
      }
    },
    // 查询
    search() {
      this.listUserTask()
    },
    // 重置
    reset() {
      this.taskForm = {}
      this.listUserTask()
    },
    // 查看
    showUser() {},
    // 分页
    change(page, size) {
      this.current = page
      this.size = size
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    }
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        Selections: [
          {
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
            }
          },
          {
            onSelect: (record, selected, selectedRows) => {
              console.log(record, selected, selectedRows)
            }
          },
          {
            onSelectAll: (selected, selectedRows, changeRows) => {
              console.log(selected, selectedRows, changeRows)
            }
          }
        ],
        onSelection: this.onSelection
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header_top {
  display: flex;
  padding: 0 0 20px 0;
}

.headertop_left {
  width: 140px;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.task_title,
.task_icon {
  color: #888888;
}
.avatar {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}
.headertop_right {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav {
  color: #333333;
}

.goback {
  color: #f44600;
  margin-right: 6px;
  padding: 1px 0 0 0;
}
.head {
  background: #ffffff;
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}

.header_left {
  display: flex;
  align-items: center;
}

.search {
  width: 68px;
  height: 36px;
  background: #f44600;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  margin: 0 14px;
}

.reset {
  width: 68px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9dbde;
  color: #666666;
}

.head_form {
  display: flex;
  align-items: center;
}

.head_form >>> .ant-form-item {
  display: flex;
  margin: 0;
  height: 36px;
  align-items: center;
}
.content {
  margin-top: 20px;
  background: #fff;
  height: calc(100vh - 196px);
  display: flex;
  flex-direction: column;
}
.head_form >>> .ant-form-item-label {
  height: 36px;
}

.head_form >>> .ant-form-item-control {
  height: 36px;
}
.head >>> .ant-select-selection--single {
  width: 200px;
  height: 36px;
}
.head_form >>> .ant-input {
  width: 200px;
  height: 36px;
  margin-right: 14px;
}
.content >>> .ant-table-wrapper {
  overflow-y: auto;
  flex: 1;
}
.header_btn {
  display: flex;
  align-items: center;
}
.head_form >>> .ant-select-selection__rendered {
  height: 36px;
  display: flex;
  align-items: center;
}
.head_form >>> .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  margin-top: -11px;
}
</style>
